/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import {
  Button, Typography, Container, Paper, makeStyles,
} from '@material-ui/core';
import { Redirect, useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import { RootState } from '../../store/root';
// import './SignIn.scss';
import useInput from '../../hooks/useInput';
import { signIn } from '../../store/user/userSlice';
import Loading from '../../ui/components/loading/Loading';
import BackButton from '../../ui/components/buttons/BackButton';
import { setAppBarTitle } from '../../home/state/homeSlice';

const useStyles = makeStyles({
  button: {
    marginBottom: '16px',
  },
});

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  isSigningIn: state.user.isSigningIn,
});
const mapDispatch = {
  onSignInSubmit: signIn,
  setTitle: setAppBarTitle,
};
const connector = connect(
  mapStateToProps,
  mapDispatch,
);
type PropsFromRedux = ConnectedProps<typeof connector>;

const SignIn: React.FC<PropsFromRedux> = ({
  user, isSigningIn, onSignInSubmit, setTitle,
}) => {
  const classes = useStyles();
  const { value: email, bind: bindEmail } = useInput('');
  const { value: password, bind: bindPassword } = useInput('');
  const location = useLocation();
  const { redirect } = queryString.parse(location.search);

  useEffect(() => {
    setTitle({ title: 'Sign In' });
  }, [setTitle]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSignInSubmit({ email, password });
    return false;
  };

  return (
    <Container className="page-sign-in page">
      {(user.user) && <Redirect to={`${redirect || '/'}`} />}
      <Loading show={isSigningIn} />
      <Paper className="form-container">
        <BackButton />
        <header>
          <Typography variant="h3" component="h1" gutterBottom>
            Sign in
          </Typography>
          <Typography className="subtitle" variant="subtitle1" gutterBottom>
            If you already have an account you may sign in below
          </Typography>
        </header>
        <form id="sign-in" onSubmit={onSubmit}>
          <TextField required label="Email" {...bindEmail} className="field" variant="outlined" type="email" />
          <TextField required label="Password" {...bindPassword} className="field" variant="outlined" type="password" />
          <Button
            className={classes.button}
            type="submit"
          >
            Sign in

          </Button>
          <Typography
            component={Link}
            variant="subtitle2"
            to="/forgot-password"
            title="Forgot password"
          >
            Forgot password?
          </Typography>
        </form>
      </Paper>
    </Container>
  );
};

export default connector(SignIn);

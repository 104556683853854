import { Selector } from 'react-redux';
import { RootState } from '../root';
import { CountryDTO } from '../model/modelSlice';

export const selectIsDemo:
Selector<RootState, boolean> = (state) => state.user.demoMode?.enabled === true;

export const selectAuthorisedCountries: Selector<RootState, CountryDTO[]> = (state) => {
  if (state.user.demoMode?.enabled) {
    return state.model.countries.filter((c) => c.available);
  }
  return [
    ...(state.user?.user?.country ? [state.user.user?.country] : []),
    ...(state.user?.user?.countries ? state.user.user?.countries : []),
  ];
};

export const selectUnauthorisedCountries: Selector<RootState, CountryDTO[]> = (state) => {
  if (state.user.demoMode?.enabled) {
    return state.model.countries.filter((c) => !c.available);
  }
  // Return countries that the user cannot access
  return state.model.countries.filter(
    (c) => state.user?.user?.country?.id !== c.id
      && state.user?.user?.countries?.find((userCountry) => userCountry.id === c.id) === undefined,
  );
};

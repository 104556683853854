/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ErrorPayloadAction = { message: string };

type ErrorState = {
  lastError: any | undefined
};

const errorSlice = createSlice({
  name: 'error',
  initialState: {
    lastError: undefined,
  } as ErrorState,
  reducers: {
    error: (state, action: PayloadAction<(ErrorPayloadAction)>) => {
      state.lastError = action.payload;
    },
  },
});

export const {
  error,
} = errorSlice.actions;

export default errorSlice.reducer;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import OnboardingTheme from '../user/containers/OnboardingTheme';

type Props = RouteProps & {
  component: React.ElementType,
};

const OnboardingRoute: React.FC<Props> = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <OnboardingTheme>
        <Component {...props} />
      </OnboardingTheme>
    )}
  />
);

export default OnboardingRoute;

import { Arguments, ExceedingIntervals } from '../../../../../types/Dataset';
import { ArgumentsForm } from './types';

// Convert quantiles and thresholds to numbers ready for submission to server
const parseFromArgumentsForm = (a: ArgumentsForm): Arguments => (
  {
    ...a,
    summary: {
      ...a.summary,
      quantiles: a.summary.quantiles?.map(
        (q) => (q !== undefined ? Number.parseFloat(q) : undefined)
      ).filter(
        (q) => q !== undefined && !Number.isNaN(q)
      ),
      thresholdOptions: a.summary.thresholdOptions?.map(
        (t) => [t[0] !== undefined ? Number.parseFloat(t[0]) : undefined, t[1]]
      ).filter(
        (t) => t[0] !== undefined && !Number.isNaN(t[0] as number)
      ) as ExceedingIntervals,
    },
  }
);

const parseToArgumentsForm = (a: Arguments): ArgumentsForm => (
  {
    ...a,
    summary: {
      ...a.summary,
      quantiles: a.summary.quantiles
        ? a.summary.quantiles.map((q) => q?.toString())
        : undefined,
      thresholdOptions: a.summary.thresholdOptions
        ? a.summary.thresholdOptions.map((t) => [t[0]?.toString(), t[1]])
        : undefined,
    },
  }
);
export { parseToArgumentsForm, parseFromArgumentsForm };

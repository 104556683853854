import { combineEpics, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getPages } from '../../store/prismic';
import { loadedPages, loadPages } from './homeSlice';
import { error } from '../../store/error/errorSlice';

const loadPagesEpic = (action$: Observable<any>) => action$.pipe(
  ofType(loadPages.type),
  mergeMap(
    () => from(getPages()).pipe(
      map((documents: any[]) => loadedPages(documents)),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

export default combineEpics(
  loadPagesEpic,
);

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CountryDTO } from '../model/modelSlice';
import { ErrorPayloadAction } from '../error/errorSlice';

export type SignInPayloadAction = PayloadAction<{ email: string, password: string }>;
export type ChangePasswordPayloadAction = PayloadAction<{
  oldPassword: string, newPassword: string }
>;

export type User = {
  id: string;
  email: string;
  organisation?: string;
  reasonForApplying?: string;
  name?: string;
  roles: {
    admin: boolean;
    approved: boolean;
    disapproved: boolean;
  };
  country?: CountryDTO;
  countries?: CountryDTO[];
  createdAt?: Date;
};

export type ForgotPasswordPayloadAction = PayloadAction<{
  email: string;
}>;
export type ResetPasswordPayloadAction = PayloadAction<{
  email: string;
  token: string;
  newPassword: string;
}>;

export type RegisterPayloadAction = PayloadAction<
Omit<User, 'roles' | 'country' | 'id' | 'countries'> &
{
  countryId: string
  password: string,
}>;

type UserState = {
  isSigningIn: boolean,
  isRegistering: boolean,
  isSigningOut: boolean,
  isDeleting: boolean,
  isLoadingUser: boolean,
  user: User | undefined,
  demoMode?: {
    enabled: boolean,
    countryId?: string,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isSigningIn: false,
    isRegistering: false,
    isSigningOut: false,
    isDeleting: false,
    isLoadingUser: false,
    demoMode: undefined,
  } as UserState,
  reducers: {
    register: (state, action: RegisterPayloadAction) => {
      state.isRegistering = true;
    },
    forgotPassword: (state, action: ForgotPasswordPayloadAction) => {},
    resetPassword: (state, action: ResetPasswordPayloadAction) => {},
    registrationSuccessful: (state, action: PayloadAction<User>) => {
      state.user = { ...action.payload };
      state.isRegistering = false;
      state.isSigningIn = false;
    },
    signIn: (state, _action: SignInPayloadAction) => {
      state.isSigningIn = true;
    },
    signOut: (state) => {
      state.isSigningOut = true;
      state.user = undefined;
      state.demoMode = { enabled: false };
    },
    signInSuccessful: (state, action: PayloadAction<User>) => {
      state.isRegistering = false;
      state.isSigningIn = false;
      state.isSigningOut = false;
      state.user = { ...action.payload };
    },
    signOutSuccessful: (state) => {
      state.isSigningOut = false;
      state.isDeleting = false;
      state.user = undefined;
      state.demoMode = undefined;
    },
    loadUser: (state) => {
      state.isLoadingUser = true;
    },
    loadUserSuccessful: (state, action: PayloadAction<User>) => {
      state.isLoadingUser = false;
      state.isSigningOut = false;
      state.isSigningIn = false;
      if (action.payload && action.payload.email !== undefined) {
        state.user = { ...action.payload };
      } else {
        state.user = undefined;
      }
    },
    deleteUser: (state) => {
      state.isDeleting = true;
    },
    deleteUserSuccessful: (state) => {
      state.isDeleting = false;
      state.user = undefined;
    },
    userError: (state, action: PayloadAction<(ErrorPayloadAction)>) => {
      state.isRegistering = false;
      state.isSigningIn = false;
      state.isSigningOut = false;
      state.isDeleting = false;
    },
    launchDemo: (state) => {
      state.demoMode = { enabled: true };
    },
    exitDemo: (state) => {
      state.demoMode = undefined;
    },
    requestAccessToCountry: (state, action: PayloadAction<string>) => { },
    changePassword: (state, action: ChangePasswordPayloadAction) => {},
  },
});

export const {
  forgotPassword,
  resetPassword,
  register,
  registrationSuccessful,
  signIn,
  signOut,
  signInSuccessful,
  signOutSuccessful,
  deleteUser,
  deleteUserSuccessful,
  loadUser,
  loadUserSuccessful,
  userError,
  launchDemo,
  exitDemo,
  requestAccessToCountry,
  changePassword,
} = userSlice.actions;

export default userSlice.reducer;

import { createSelector } from '@reduxjs/toolkit';
import { Selector } from 'react-redux';
import { RootState } from '../root';
import { ExtendedDatasetDTO } from '../../../../types/Dataset';

type Props = string;

const selectDatasets: Selector<RootState, ExtendedDatasetDTO[], Props> = (
  state,
) => state.dataset.datasets;

const selectIsDeleted: Selector<RootState, string[], Props> = (
  state,
) => state.dataset.deleted || [];

export const makeGetDataset = () => createSelector(
  [selectDatasets, (_: RootState, props: Props) => props],
  (datasets, id) => datasets.find((ds) => ds.id === id),
);

export const makeSelectIsDeleted = () => createSelector(
  [selectIsDeleted, (_: RootState, props: Props) => props],
  (datasets, id) => datasets.includes(id),
);

import { Container, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setAppBarTitle } from '../../home/state/homeSlice';
import { RootState } from '../../store/root';
import WelcomeApproved from '../components/WelcomeApproved';
import WelcomeAwaitingApproval from '../components/WelcomeAwaitingApproval';
import WelcomeDisapproved from '../components/WelcomeDisapproved';
import WelcomeUnauthenticated from '../components/WelcomeUnauthenticated';
import { launchDemo, signOut } from '../../store/user/userSlice';

const logo = require('../../img/maplaria-logo.png');

const mapStateToProps = (state: RootState) => ({
  user: state.user.user,
  isDemo: state.user.demoMode !== undefined && state.user.demoMode.enabled,
});
const mapDispatch = {
  onSignOut: signOut,
  startDemo: launchDemo,
  setTitle: setAppBarTitle,
};
const connector = connect(
  mapStateToProps,
  mapDispatch,
);
type PropsFromRedux = ConnectedProps<typeof connector>;

const WelcomePage: React.FC<PropsFromRedux> = ({
  user, onSignOut, setTitle, startDemo, isDemo,
}) => {
  let content;
  useEffect(() => {
    setTitle({ title: 'Welcome' });
  });

  if (!user) {
    content = <WelcomeUnauthenticated startDemo={startDemo} />;
  } else if (user.roles.admin || user.roles.approved) {
    content = <WelcomeApproved />;
  } else if (user.roles.disapproved) {
    content = <WelcomeDisapproved email={user.email} />;
  } else {
    content = <WelcomeAwaitingApproval email={user.email} onSignOut={onSignOut} />;
  }

  return (
    <div className="background">
      <Container className="page-sign-in center-content page">
        <Paper className="form-container">
          <header>
            <img src={logo} alt="Maplaria" style={{ maxWidth: '300px' }} />
            {(isDemo) && <Redirect to="/upload" />}
          </header>
          {content}
        </Paper>
      </Container>
    </div>

  );
};

export default connector(WelcomePage);

import { Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType, combineEpics } from 'redux-observable';
import {
  mergeMap, map, catchError, flatMap,
} from 'rxjs/operators';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  loadApprovalQueue,
  loadedApprovalQueue,
  approveUser,
  ApproveDisapprovePayloadAction,
  approveUserSuccess,
  loadProcessingDatasets,
  loadedDatasets,
  addCountry,
  AddCountryPayloadAction,
  EditCountryPayloadAction,
  editCountry,
  toggleAddModelDataToCountryDialog,
  rebuildDatasets,
  loadCountryAccessRequests,
  approveCountryAccessRequest,
  approveCountryAccessRequestSuccess,
  loadedCountryAccessRequests,
} from './adminSlice';
import {
  countryAdded,
  countryEdited,
} from '../../store/model/modelSlice';
import { headers, URL_ADMIN } from '../../utils/ajax';
import { error } from '../../store/error/errorSlice';

const loadApprovalQueueEpic = (action$: Observable<any>) => action$.pipe(
  ofType(loadApprovalQueue.type),
  mergeMap(
    () => ajax.get(
      `${URL_ADMIN}/users`,
    ).pipe(
      map(({ response }) => loadedApprovalQueue(response)),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

const approveUserEpic = (action$: Observable<any>) => action$.pipe(
  ofType(approveUser.type),
  mergeMap(
    (action: PayloadAction<ApproveDisapprovePayloadAction>) => ajax.post(
      `${URL_ADMIN}/users/${action.payload.id}/set-approval`,
      { approve: action.payload.approve },
      headers,
    ).pipe(
      map(() => approveUserSuccess(action.payload)),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

const loadDatatsetsEpic = (action$: Observable<any>) => action$.pipe(
  ofType(loadProcessingDatasets.type),
  mergeMap(
    () => ajax.get(
      `${URL_ADMIN}/datasets`,
    ).pipe(
      map(({ response }) => loadedDatasets(response)),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

const addCountryEpic = (action$: Observable<any>) => action$.pipe(
  ofType(addCountry.type),
  mergeMap(
    (action: PayloadAction<AddCountryPayloadAction>) => ajax.post(
      `${URL_ADMIN}/country`,
      action.payload,
      headers,
    ).pipe(
      flatMap(({ response }) => of(
        countryAdded(response.data),
        toggleAddModelDataToCountryDialog(response.data.id),
      )),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

const editCountryEpic = (action$: Observable<any>) => action$.pipe(
  ofType(editCountry.type),
  mergeMap(
    (action: PayloadAction<EditCountryPayloadAction>) => ajax.put(
      `${URL_ADMIN}/country/${action.payload.id}`,
      action.payload,
      headers,
    ).pipe(
      map(({ response }) => countryEdited(response.data)),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

const rebuildDatasetsEpic = (action$: Observable<any>) => action$.pipe(
  ofType(rebuildDatasets.type),
  mergeMap(
    () => ajax.post(
      `${URL_ADMIN}/datasets/rebuild`,
    ).pipe(
      map(({ response }) => loadedDatasets(response)),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

const loadCountryAccessRequestsEpic = (action$: Observable<any>) => action$.pipe(
  ofType(loadCountryAccessRequests.type),
  mergeMap(
    () => ajax.get(
      `${URL_ADMIN}/users/country-access-requests`,
    ).pipe(
      map(({ response }) => loadedCountryAccessRequests(response.requests)),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

const approveCountryAccessRequestEpic = (action$: Observable<any>) => action$.pipe(
  ofType<PayloadAction<ApproveDisapprovePayloadAction>>(approveCountryAccessRequest.type),
  mergeMap(
    (action) => ajax.post(
      `${URL_ADMIN}/users/country-access-requests/${action.payload.id}`,
      { approve: action.payload.approve },
      headers,
    ).pipe(
      map(() => approveCountryAccessRequestSuccess(action.payload)),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

export default combineEpics(
  loadApprovalQueueEpic,
  approveUserEpic,
  loadDatatsetsEpic,
  addCountryEpic,
  editCountryEpic,
  rebuildDatasetsEpic,
  loadCountryAccessRequestsEpic,
  approveCountryAccessRequestEpic,
);

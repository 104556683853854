import { combineEpics, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, mapTo, mergeMap } from 'rxjs/operators';
import { headers, URL_SUPPORT } from '../../utils/ajax';
import { error } from '../error/errorSlice';
import { sendQuery, SendQueryPayloadAction, sentQuerySuccessfully } from './supportSlice';

const sendSupportQueryEpic = (action$: Observable<any>) => action$.pipe(
  ofType<SendQueryPayloadAction>(sendQuery.type),
  mergeMap(
    (action) => ajax.post(`${URL_SUPPORT}/contact`, { ...action.payload }, headers).pipe(
      mapTo(sentQuerySuccessfully()),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

export default combineEpics(
  sendSupportQueryEpic,
);

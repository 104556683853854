/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadDatasetSuccessful, deletedDataset } from './datasetSlice';
import { ExtendedDatasetDTO, DatasetDTO } from '../../../../types/Dataset';

type DatasetsPayloadAction = PayloadAction<DatasetDTO[]>;

type DatasetIndexState = {
  // Instead of using arrays maybe these should just be object maps
  myDatasetIndexes: DatasetDTO[];
  publicDatasetIndexes: DatasetDTO[];
  loading: boolean;
  mySort?: Sort;
  publicSort?: Sort;
};

export type Order = 'asc' | 'desc';
export type Sort = {
  orderBy: string;
  order: Order;
};

type ChangeSortPayloadAction = PayloadAction<{
  type: 'mySort' | 'publicSort';
  sort: Sort;
}>;

// TODO we need to get better types between ExtendedDatasetDTO and DatasetDTO
function removeProccessedData(ds: ExtendedDatasetDTO): ExtendedDatasetDTO {
  return {
    ...ds,
    countryWithModelData: undefined,
    output: undefined,
    input: {
      ...ds.input,
      parsed: {
        averageBySpatialUnitBoundary: undefined,
        point: undefined,
        spatiallyContinuousBoundary: undefined,
      },
    },
  };
}

// THIS IS PERSISTED
const datasetUploadSlice = createSlice({
  name: 'datasetIndex',
  initialState: {
    myDatasetIndexes: [],
    publicDatasetIndexes: [],
    loading: false,
    mySort: {
      orderBy: 'createdAt',
      order: 'desc',
    },
    publicSort: {
      orderBy: 'createdAt',
      order: 'desc',
    },
  } as DatasetIndexState,
  reducers: {
    loadMyDatasets: (state) => {
      state.loading = true;
    },
    loadMyDatasetsSuccessful: (state, action: DatasetsPayloadAction) => {
      state.myDatasetIndexes = action.payload;
      state.loading = false;
    },
    loadPublicDatasets: (state) => {
      state.loading = true;
    },
    loadPublicDatasetsSuccessful: (state, action: DatasetsPayloadAction) => {
      state.publicDatasetIndexes = action.payload;
      state.loading = false;
    },
    changeSort: (state, action: ChangeSortPayloadAction) => {
      state[action.payload.type] = action.payload.sort;
    },
  },
  extraReducers: {
    // Catch any load successful events and update the index
    [loadDatasetSuccessful.type]: (state, action: PayloadAction<ExtendedDatasetDTO>) => {
      const idx = state.myDatasetIndexes.findIndex((d) => d.id === action.payload.id);
      // Remove any processed data, too big to persist in the indexes
      const ds = removeProccessedData(action.payload);
      if (idx > -1) {
        state.myDatasetIndexes[idx] = ds;
      }
      const publicIdx = state.publicDatasetIndexes.findIndex((d) => d.id === action.payload.id);
      if (publicIdx > -1) {
        state.publicDatasetIndexes[publicIdx] = ds;
      }
      state.publicDatasetIndexes = state.publicDatasetIndexes.filter((d) => d.public === true);
    },
    [deletedDataset.type]: (state, action: PayloadAction<string>) => {
      state.myDatasetIndexes = state.myDatasetIndexes.filter(
        (ds) => ds.id !== action.payload,
      );
      state.publicDatasetIndexes = state.publicDatasetIndexes.filter(
        (ds) => ds.id !== action.payload,
      );
    },
  },
});

export const {
  loadMyDatasets,
  loadMyDatasetsSuccessful,
  loadPublicDatasets,
  loadPublicDatasetsSuccessful,
  changeSort,
} = datasetUploadSlice.actions;

export default datasetUploadSlice.reducer;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AppBarTitleActionPayload = { title: string };

type HomeState = {
  mobileOpen: boolean,
  appBarTitle: string,
  pages: any[],
};

const homeSlice = createSlice({
  name: 'home',
  initialState: {
    mobileOpen: true,
    appBarTitle: '',
    pages: [],
  } as HomeState,
  reducers: {
    toggleMobileDrawer: (state) => {
      state.mobileOpen = !state.mobileOpen;
    },
    closeMobileDrawer: (state) => {
      state.mobileOpen = false;
    },
    setAppBarTitle: (state, action: PayloadAction<AppBarTitleActionPayload>) => {
      state.appBarTitle = action.payload.title;
    },
    loadPages: () => {},
    loadedPages: (state, action: PayloadAction<any[]>) => {
      state.pages = action.payload;
    },
  },
});

export const {
  toggleMobileDrawer, closeMobileDrawer, setAppBarTitle, loadPages, loadedPages,
} = homeSlice.actions;

export default homeSlice.reducer;

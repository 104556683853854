/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  useLocation,
} from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../store/root';

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.user.user?.roles.approved || state.user.demoMode?.enabled,
});
const connector = connect(
  mapStateToProps,
);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = RouteProps & PropsFromRedux & {
  component: React.ElementType,
};

const PrivateRoute: React.FC<Props> = ({ component: Component, isAuthenticated, ...rest }) => {
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => (
        isAuthenticated
          ? <Component {...props} />
          : <Redirect to={`/landing?redirect=${location.pathname}`} />
      )}
    />
  );
};

export default connector(PrivateRoute);

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import {
  createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { ExtendedDatasetDTO } from '../../../../types/Dataset';

export type LoadDatasetPayloadAction = PayloadAction<{
  id: string,
}>;

export type ToggleEditDialogPayloadAction = PayloadAction<{
  id: string,
  open: boolean,
}>;

export type SetPublicPayloadAction = PayloadAction<{
  id: string,
  isPublic: boolean,
}>;

export type SetNamePayloadAction = PayloadAction<{
  id: string,
  name: string,
}>;

type DatasetOutputState = {
  datasets: ExtendedDatasetDTO[],
  loadingDatasets: { [key: string]: boolean | undefined }
  cancelling: { [key: string]: boolean | undefined }
  editing: { [key: string]: boolean | undefined }
  editDialogOpen: { [key: string]: boolean | undefined }
  deleted?: string[],
};

// We must not persist actual datasets to local storage, they are too large
const datasetSlice = createSlice({
  name: 'datasetOutput',
  initialState: {
    datasets: [],
    loadingDatasets: {},
    cancelling: {},
    editing: {},
    deleted: [],
    editDialogOpen: {},
  } as DatasetOutputState,
  reducers: {
    loadDataset: (state, action: LoadDatasetPayloadAction) => {
      state.loadingDatasets[action.payload.id] = true;
      state.editing[action.payload.id] = false;
    },
    loadDatasetSuccessful: (state, action: PayloadAction<ExtendedDatasetDTO>) => {
      const idx = state.datasets.findIndex((ds) => ds.id === action.payload.id);
      if (idx > -1) {
        state.datasets[idx] = {
          ...action.payload,
        };
      } else {
        state.datasets = [...state.datasets, action.payload];
      }
      state.loadingDatasets[action.payload.id] = undefined;
    },
    cancelProcessing(state, action: PayloadAction<string>) {
      state.cancelling[action.payload] = true;
    },
    cancelProcessingSuccessful(state, action: PayloadAction<string>) {
      state.cancelling[action.payload] = undefined;
    },
    toggleEditDialog(state, action: ToggleEditDialogPayloadAction) {
      state.editDialogOpen[action.payload.id] = action.payload.open;
    },
    editSaved(state, action: PayloadAction<string>) {
      state.editDialogOpen[action.payload] = false;
      state.editing[action.payload] = false;
    },
    setPublic(state, action: SetPublicPayloadAction) {
      state.editing[action.payload.id] = true;
    },
    setName(state, action: SetNamePayloadAction) {
      state.editing[action.payload.id] = true;
    },
    cloneDataset(state, action: PayloadAction<string>) {
      state.editing[action.payload] = true;
    },
    deleteDataset(state, action: PayloadAction<string>) {
      state.editing[action.payload] = true;
    },
    deletedDataset(state, action: PayloadAction<string>) {
      state.editing[action.payload] = false;
      state.deleted = [...(state.deleted ? state.deleted : []), action.payload];
    },
  },
});

export const {
  loadDataset,
  loadDatasetSuccessful,
  cancelProcessingSuccessful,
  cancelProcessing,
  setPublic,
  setName,
  cloneDataset,
  toggleEditDialog,
  editSaved,
  deleteDataset,
  deletedDataset,
} = datasetSlice.actions;

export default datasetSlice.reducer;

import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

type Props = {
  show: boolean,
};

const Loading: React.FC<Props> = ({ show }) => (
  <Backdrop open={show} style={{ zIndex: 100 }}>
    <CircularProgress color="inherit" />
  </Backdrop>
);

export default Loading;

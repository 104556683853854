/* eslint-disable react/jsx-props-no-spreading */
import {
  Button, Container, Paper, Typography,
} from '@material-ui/core';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setAppBarTitle } from '../../home/state/homeSlice';
import useInput from '../../hooks/useInput';
import { RootState } from '../../store/root';
import BackButton from '../../ui/components/buttons/BackButton';
import PasswordInput from '../components/PasswordInput';
import { resetPassword } from '../../store/user/userSlice';

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const mapDispatch = {
  onResetSubmit: resetPassword,
  setTitle: setAppBarTitle,
};

const connector = connect(
  mapStateToProps,
  mapDispatch,
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ResetPasswordPage: React.FC<PropsFromRedux> = ({
  onResetSubmit, setTitle,
}) => {
  const { value: newPassword, bind: bindNewPassword, dirty: newPasswordDirty } = useInput('');
  const { value: newPasswordRepeat, bind: bindNewPasswordRepeat, dirty: newPasswordRepeatDirty } = useInput('');

  useEffect(() => {
    setTitle({ title: 'Forgot Password' });
  }, [setTitle]);

  const location = useLocation();
  const { email, token } = queryString.parse(location.search);

  if (typeof email !== 'string' || typeof token !== 'string') {
    return (
      <Container className="page-sign-in page">
        <Typography variant="subtitle1">Invalid link, please request another.</Typography>
      </Container>
    );
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onResetSubmit({ email, token, newPassword });
    return false;
  };

  const passwordValid = newPassword.length > 5;
  const passwordRepeatValid = newPassword === newPasswordRepeat;
  const valid = passwordValid && passwordRepeatValid;

  return (
    <Container className="page-sign-in page">
      <Paper className="form-container">
        <BackButton />
        <header>
          <Typography variant="h3" component="h1" gutterBottom>
            Reset
          </Typography>
          <Typography className="subtitle" variant="subtitle1" gutterBottom>
            Enter a new password to reset it
          </Typography>
        </header>
        <form id="sign-in" onSubmit={onSubmit}>
          <PasswordInput
            label="New password"
            value={bindNewPassword.value}
            onChange={bindNewPassword.onChange}
            id="new-password"
            helperText="Must be > 6 characters long"
            error={!passwordValid && newPasswordDirty}
          />
          <PasswordInput
            label="New password repeat"
            value={bindNewPasswordRepeat.value}
            onChange={bindNewPasswordRepeat.onChange}
            id="new-password-repeat"
            error={!passwordRepeatValid && newPasswordRepeatDirty}
            helperText={!passwordRepeatValid && newPasswordRepeatDirty ? 'Passwords do not match' : undefined}
          />
          <Button disabled={!valid} type="submit">Next</Button>
        </form>
      </Paper>
    </Container>
  );
};

export default connector(ResetPasswordPage);

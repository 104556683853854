import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { URL_DATASET, URL_PUBLIC_DATASET } from '../../utils/ajax';
import { error } from '../error/errorSlice';
import {
  loadMyDatasets, loadMyDatasetsSuccessful, loadPublicDatasetsSuccessful, loadPublicDatasets,
} from './datasetIndexSlice';
import { RootState } from '../root';
import { selectIsDemo } from '../user/userSelectors';

const loadMyDatasetsEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(loadMyDatasets.type),
  withLatestFrom(state$.pipe(map(selectIsDemo))),
  mergeMap(
    (([, isDemo]) => {
      if (isDemo) {
        return of(loadMyDatasetsSuccessful([]));
      }
      return ajax.get(
        URL_DATASET,
      ).pipe(
        map(({ response }) => loadMyDatasetsSuccessful(response.data)),
        catchError(({ message }) => of(error({ message }))),
      );
    }),
  ),
);

const loadPublicDatasetsEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType(loadPublicDatasets.type),
  mergeMap(
    (() => ajax.get(
      URL_PUBLIC_DATASET,
    ).pipe(
      map(({ response }) => loadPublicDatasetsSuccessful(response.data)),
      catchError(({ message }) => of(error({ message }))),
    )),
  ),
);

export default combineEpics(
  loadMyDatasetsEpic,
  loadPublicDatasetsEpic,
);

import { combineEpics, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { Observable, empty } from 'rxjs';
import { PayloadAction } from '@reduxjs/toolkit';
import { error, ErrorPayloadAction } from './errorSlice';

const errorEpic = (action$: Observable<any>) => action$.pipe(
  ofType(error.type),
  mergeMap((action: PayloadAction<ErrorPayloadAction>) => {
    // eslint-disable-next-line no-alert
    alert(action.payload.message);
    return empty();
  }),
);

export default combineEpics(errorEpic);

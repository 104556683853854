import { ofType, combineEpics } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {
  mergeMap, catchError, map, flatMap, ignoreElements, tap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { PayloadAction, Action } from '@reduxjs/toolkit';
import {
  register,
  RegisterPayloadAction,
  userError,
  registrationSuccessful,
  signIn,
  SignInPayloadAction,
  signInSuccessful,
  signOut,
  signOutSuccessful,
  loadUserSuccessful,
  deleteUser,
  deleteUserSuccessful,
  loadUser,
  requestAccessToCountry,
  ChangePasswordPayloadAction,
  changePassword,
  ForgotPasswordPayloadAction,
  forgotPassword,
  resetPassword,
  ResetPasswordPayloadAction,
} from './userSlice';
import { error, ErrorPayloadAction } from '../error/errorSlice';
import { URL_AUTH } from '../../utils/ajax';
import { loadMyDatasetsSuccessful } from '../dataset/datasetIndexSlice';
import history from '../../utils/history';

const forgotPasswordEpic = (action$: Observable<any>) => action$.pipe(
  ofType<ForgotPasswordPayloadAction>(forgotPassword.type),
  mergeMap(
    (action) => ajax.post(
      `${URL_AUTH}/password/forgot`, action.payload,
    ).pipe(
      tap(() => {
        alert('Please check your email!');
        history.push('/');
      }),
      ignoreElements(),
      catchError((e) => of(userError({ message: e.message }))),
    ),
  ),
);

const resetPasswordEpic = (action$: Observable<any>) => action$.pipe(
  ofType<ResetPasswordPayloadAction>(resetPassword.type),
  mergeMap(
    (action) => ajax.post(
      `${URL_AUTH}/password/reset`, action.payload,
    ).pipe(
      tap(() => {
        alert('Password reset!');
        history.push('/');
      }),
      ignoreElements(),
      catchError((e) => of(userError({ message: e.message }))),
    ),
  ),
);

const registerUserEpic = (action$: Observable<any>) => action$.pipe(
  ofType<RegisterPayloadAction>(register.type),
  mergeMap(
    (action) => ajax.post(
      `${URL_AUTH}/register`, action.payload,
    ).pipe(
      map(({ response }) => registrationSuccessful(response)),
      catchError((e) => of(userError({ message: e.message }))),
    ),
  ),
);

const signInUserEpic = (action$: Observable<any>) => action$.pipe(
  ofType<SignInPayloadAction>(signIn.type),
  mergeMap(
    (action) => ajax.post(
      `${URL_AUTH}/signin`, action.payload,
    )
      .pipe(
        map(({ response }) => signInSuccessful(response)),
        catchError((e) => of(userError({ message: 'Could not sign in, are your username and password correct?' }))),
      ),
  ),
);

const signOutUserEpic = (action$: Observable<Action>) => action$.pipe(
  ofType(signOut.type),
  mergeMap(
    () => ajax.post(
      `${URL_AUTH}/signout`,
    ).pipe(
      flatMap(() => of(
        signOutSuccessful(),
        loadMyDatasetsSuccessful([]),
      )),
      catchError((e) => of(userError({ message: e.message }))),
    ),
  ),
);

const changePasswordEpic = (action$: Observable<any>) => action$.pipe(
  ofType<ChangePasswordPayloadAction>(changePassword.type),
  mergeMap(
    (action) => ajax.post(
      `${URL_AUTH}/password`, action.payload,
    )
      .pipe(
        tap(() => {
          alert('Password changed');
        }),
        ignoreElements(),
        catchError((e) => of(userError({ message: 'Could not change password, is your old password correct?' }))),
      ),
  ),
);

const deleteUserEpic = (action$: Observable<Action>) => action$.pipe(
  ofType(deleteUser.type),
  mergeMap(
    () => ajax.post(
      `${URL_AUTH}/delete`,
    ).pipe(
      flatMap(() => of(
        deleteUserSuccessful(),
        loadMyDatasetsSuccessful([]),
      )),
      catchError((e) => of(userError({ message: e.message }))),
    ),
  ),
);

const loadUserEpic = (
  action$: Observable<Action>,
) => action$.pipe(
  ofType(loadUser.type),
  mergeMap(
    () => ajax.get(
      `${URL_AUTH}/me`,
    ).pipe(
      map(({ response }) => loadUserSuccessful(response)),
      catchError((e) => of(userError({ message: e.message }))),
    ),
  ),
);

const requestAccessToCountryEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType<PayloadAction<string>>(requestAccessToCountry.type),
  mergeMap(
    ({ payload }) => ajax.post(
      `${URL_AUTH}/request-country`,
      { countryId: payload },
    ).pipe(
      ignoreElements(),
      catchError((e) => of(userError({ message: e.message }))),
    ),
  ),
);

// Forward on any local errors to the global error handler
const errorUserEpic = (action$: Observable<any>) => action$.pipe(
  ofType(userError.type),
  map((action: PayloadAction<ErrorPayloadAction>) => error({ message: action.payload.message })),
);

export default combineEpics(
  registerUserEpic,
  signInUserEpic,
  signOutUserEpic,
  loadUserEpic,
  errorUserEpic,
  deleteUserEpic,
  changePasswordEpic,
  requestAccessToCountryEpic,
  forgotPasswordEpic,
  resetPasswordEpic,
);

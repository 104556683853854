import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  link: {
    color: 'blue',
    marginBottom: '16px',
    display: 'block',
  },
});

const WelcomeApproved: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="subtitle2" component="h6" gutterBottom>
        Your account has been approved
      </Typography>
      <Link
        to="/"
        className={classes.link}
        title="Continue"
      >
        Continue
      </Link>
    </>
  );
};

export default WelcomeApproved;

import React, { useState } from 'react';
import {
  FormControl, InputLabel, OutlinedInput, IconButton, InputAdornment, FormHelperText,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

type Props = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  helperText?: string;
  label?: string;
  id: string;
  error: boolean;
};

const PasswordInput: React.FC<Props> = ({
  value, onChange, helperText, label = 'Password', id, error,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <FormControl
      variant="outlined"
      error={error}
      required
      fullWidth
      style={{ marginBottom: '16px' }}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        type={showPassword ? 'text' : 'password'}
        value={value}
        id={id}
        onChange={onChange}
        label={label}
        required
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              // onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default PasswordInput;

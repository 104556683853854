/* eslint-disable react/jsx-props-no-spreading */
import {
  Button, Container, Paper, Typography,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { setAppBarTitle } from '../../home/state/homeSlice';
import useInput from '../../hooks/useInput';
import { RootState } from '../../store/root';
import BackButton from '../../ui/components/buttons/BackButton';
import { forgotPassword } from '../../store/user/userSlice';

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});
const mapDispatch = {
  onForgotSubmit: forgotPassword,
  setTitle: setAppBarTitle,
};
const connector = connect(
  mapStateToProps,
  mapDispatch,
);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ForgotPasswordPage: React.FC<PropsFromRedux> = ({
  onForgotSubmit, setTitle,
}) => {
  const { value: email, bind: bindEmail } = useInput('');
  useEffect(() => {
    setTitle({ title: 'Forgot Password' });
  }, [setTitle]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onForgotSubmit({ email });
    return false;
  };

  return (
    <Container className="page-sign-in page">
      <Paper className="form-container">
        <BackButton />
        <header>
          <Typography variant="h3" component="h1" gutterBottom>
            Forgot
          </Typography>
          <Typography className="subtitle" variant="subtitle1" gutterBottom>
            If you have forgotten your password enter your email
          </Typography>
        </header>
        <form id="forgot-password" onSubmit={onSubmit}>
          <TextField required label="Email" {...bindEmail} className="field" variant="outlined" type="email" />
          <Button type="submit">Next</Button>
        </form>
      </Paper>
    </Container>
  );
};

export default connector(ForgotPasswordPage);

import { combineEpics, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { error } from '../error/errorSlice';
import { getPage, getHelpPage } from '../prismic';
import { loadPage, loadedPage, LoadPagePayloadAction } from './contentSlice';

const loadPageEpic = (action$: Observable<any>) => action$.pipe(
  ofType<LoadPagePayloadAction>(loadPage.type),
  mergeMap(
    (action) => from(
      action.payload.type === 'page'
        ? getPage(action.payload.uid)
        : getHelpPage(action.payload.uid),
    ).pipe(
      map((document: any) => loadedPage(document)),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

export default combineEpics(
  loadPageEpic,
);

import React, { useState } from 'react';
import { Link } from '@material-ui/core';
import TermsDialog from './TermsDialog';

const TermsLinks: React.FC = () => {
  const [showingDialog, setShowingDialog] = useState<'terms' | 'privacy' | undefined>();

  const onClickTerms = () => setShowingDialog('terms');
  const onClickPrivacy = () => setShowingDialog('privacy');
  const onClose = () => setShowingDialog(undefined);

  return (
    <>
      By applying you consent to the MapLaria
      {' '}
      <Link href="#terms" onClick={onClickTerms}>
        Terms &amp; Conditions
      </Link>
      {' '}
      and
      {' '}
      <Link href="#terms" onClick={onClickPrivacy}>Privacy Policy</Link>
      <TermsDialog onClose={onClose} state={showingDialog} />
    </>
  );
};

export default TermsLinks;

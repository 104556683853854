/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CountriesLoadedPayloadAction = CountryDTO[];
export type CountryAddedEditedPayloadAction = CountryDTO;
export type CountryRemovedPayloadAction = string;

export type CountryDTO = {
  id: string,
  name: string,
  createdAt: Date,
  updatedAt: Date,
  modelAvailable: boolean,
  baselineAvailable: boolean,
  boundaryAvailable: boolean,
  available: boolean,
};

type ModelState = {
  countries: CountryDTO[],
  loadingCountries: boolean
};

const modelSlice = createSlice({
  name: 'model',
  initialState: {
    loadingCountries: false,
    countries: [],
  } as ModelState,
  reducers: {
    loadCountries: (state) => {
      state.loadingCountries = true;
    },
    loadCountriesSuccessful: (state, action: PayloadAction<CountriesLoadedPayloadAction>) => {
      state.countries = action.payload;
    },
    countryRemoved: (state, action: PayloadAction<CountryRemovedPayloadAction>) => {
      state.countries = state.countries.filter((country) => country.id !== action.payload);
    },
    countryEdited: (state, action: PayloadAction<CountryAddedEditedPayloadAction>) => {
      const idx = state.countries.findIndex((country) => country.id === action.payload.id);
      if (idx >= 0) {
        state.countries[idx] = { ...action.payload };
      }
    },
    modelAddedToCountry(state, action: PayloadAction<string>) {
      const idx = state.countries.findIndex((country) => country.id === action.payload);
      if (idx >= 0) {
        state.countries[idx].modelAvailable = true;
      }
    },
    baselineAddedToCountry(state, action: PayloadAction<string>) {
      const idx = state.countries.findIndex((country) => country.id === action.payload);
      if (idx >= 0) {
        state.countries[idx].baselineAvailable = true;
      }
    },
    boundaryAddedToCountry(state, action: PayloadAction<string>) {
      const idx = state.countries.findIndex((country) => country.id === action.payload);
      if (idx >= 0) {
        state.countries[idx].boundaryAvailable = true;
      }
    },
    countryAdded: (state, action: PayloadAction<CountryAddedEditedPayloadAction>) => {
      if (!state.countries.find((country) => country.id === action.payload.id)) {
        state.countries = [...state.countries, action.payload];
      }
    },
  },
});

export const {
  loadCountries,
  loadCountriesSuccessful,
  countryAdded,
  countryEdited,
  modelAddedToCountry,
  baselineAddedToCountry,
  boundaryAddedToCountry,
} = modelSlice.actions;

export default modelSlice.reducer;

import React from 'react';
import { Typography } from '@material-ui/core';

type Props = {
  email: string,
};

const WelcomeDisapproved: React.FC<Props> = ({ email }) => (
  <Typography variant="subtitle2" component="h6" gutterBottom>
    Your account,
    {' '}
    {email}
    , has not been approved for access.
  </Typography>
);

export default WelcomeDisapproved;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SendQueryPayloadAction = PayloadAction<{
  token: string;
  email: string;
  query: string;
  subject: string;
}>;

type SupportState = {
  sentQuery: boolean;
  loading: boolean;
};

const supportSlice = createSlice({
  name: 'content',
  initialState: {
    sentQuery: false,
    loading: false,
  } as SupportState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendQuery: (state, _: SendQueryPayloadAction) => {
      state.loading = true;
    },
    sentQuerySuccessfully: (state) => {
      state.loading = false;
      state.sentQuery = true;
    },
    resetQueryState: (state) => {
      state.loading = false;
      state.sentQuery = false;
    },
  },
});

export const { sendQuery, sentQuerySuccessfully, resetQueryState } = supportSlice.actions;

export default supportSlice.reducer;

import React from 'react';
import './App.scss';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import store from './store';
import Routes from './Routes';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#94090f',
    },
    secondary: {
      main: '#d81b60',
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        '&.page': {
          paddingTop: '32px',
        },
      },
    },
    MuiFormControl: {
      root: {
        '&.field-select': {
          marginBottom: '32px',
          textAlign: 'left',
          width: '100%',
        },
      },
    },
  },
});

const App: React.FC<any> = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Routes />
      </SnackbarProvider>
    </MuiThemeProvider>
  </Provider>
);

export default App;

/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';
import { Selector } from 'react-redux';
import { RootState } from '../../root';
import { ArgumentsForm } from './types';

// eslint-disable-next-line import/prefer-default-export
// export const makeIsLoading = () => createSelector<
// RootState, Props, ExtendedDatasetDTO[], string, ExtendedDatasetDTO | undefined
// >(
//   [getDatasets, (state, props) => props],
//   (datasets, id) => datasets.find((ds) => ds.id === id),
// );

export const selectArgumentsForm: Selector<RootState, ArgumentsForm | undefined, string> = (
  state: RootState, props,
) => state.datasetUploads.uploadState[props]?.argumentsForm;

export const makeIsArgumentsFormValid = () => createSelector(
  [selectArgumentsForm],
  (form) => {
    const details = form?.summary;
    if (details) {
      const quantilesValid = details.quantiles ? details.quantiles?.some(
        (x) => x !== undefined && !Number.isNaN(Number.parseFloat(x))
      ) : true;
      const thresholdsValid = details.thresholdOptions
        ? details.thresholdOptions?.some(
          (x) => x[0] !== undefined && !Number.isNaN(Number.parseFloat(x[0]))
        ) : true;
      return form?.year.length && quantilesValid && thresholdsValid && (
        details.pointEstimates || details.standardErrors
        || details.quantiles !== undefined || details.thresholdOptions !== undefined
      ) === true;
    }
    return false;
  },
);

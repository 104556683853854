/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../store/user/userSlice';
import { Process } from '../../../../types/Dataset';

export type ApproveDisapprovePayloadAction = { id: string, approve: boolean };
export type LoadedApprovalQueuePayloadAction = { users: User[] };

export type LoadedDatasetsPayloadAction = { datasets: any[] };

export type AddCountryPayloadAction = { modelAvailable: boolean, name: string };
export type EditCountryPayloadAction = { id: string, modelAvailable?: boolean, name?: string };
export type Countries = { users: User[] };

export type UserAdministration = {
  userApprovalQueue: User[],
  isLoadingApprovalQueue: boolean,
};

export type DatasetAdministration = {
  datasets: {
    id: string,
    name: string,
    process: Process,
    user: {
      id: string,
      email: string,
    }
  }[],
  isLoadingProcessing: boolean,
};

export type ModelAdministration = {
  addCountryDialogOpen: boolean,
  addModelDataToCountryDialogOpen: string | undefined,
};

export type CountryAccessRequestAdministration = {
  isLoading: boolean,
  countryAccessRequestQueue: any[],
};

type AdminState = {
  users: UserAdministration,
  datasets: DatasetAdministration,
  model: ModelAdministration,
  countryAccessRequests?: CountryAccessRequestAdministration,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    users: {
      isLoadingApprovalQueue: false,
      userApprovalQueue: [],
    },
    datasets: {
      isLoadingProcessing: false,
      datasets: [],
    },
    model: {
      addCountryDialogOpen: false,
      addModelDataToCountryDialogOpen: undefined,
    },
    countryAccessRequests: {
      isLoading: false,
      countryAccessRequestQueue: [],
    },
  } as AdminState,
  reducers: {
    loadApprovalQueue: (state) => {
      state.users.isLoadingApprovalQueue = true;
    },
    loadedApprovalQueue: (state, action: PayloadAction<LoadedApprovalQueuePayloadAction>) => {
      state.users.userApprovalQueue = action.payload.users;
      state.users.isLoadingApprovalQueue = false;
    },
    approveUser: (state, _action: PayloadAction<ApproveDisapprovePayloadAction>) => {
    },
    approveUserSuccess: (state, action: PayloadAction<ApproveDisapprovePayloadAction>) => {
      const modifiedUser = state.users.userApprovalQueue.find(
        (user) => user.id === action.payload.id,
      );

      if (modifiedUser) {
        modifiedUser.roles.approved = action.payload.approve;
        modifiedUser.roles.disapproved = !action.payload.approve;
      }
    },
    toggleAddCountryDialog(state, action: PayloadAction<boolean>) {
      state.model.addCountryDialogOpen = action.payload;
    },
    toggleAddModelDataToCountryDialog(state, action: PayloadAction<string | undefined>) {
      state.model.addModelDataToCountryDialogOpen = action.payload;
    },
    addCountry(state, action: PayloadAction<AddCountryPayloadAction>) {
      state.model.addCountryDialogOpen = false;
    },
    editCountry(state, action: PayloadAction<EditCountryPayloadAction>) { },
    loadProcessingDatasets: (state) => {
      state.datasets.isLoadingProcessing = true;
    },
    loadedDatasets: (state, action: PayloadAction<LoadedDatasetsPayloadAction>) => {
      state.datasets.isLoadingProcessing = false;
      state.datasets.datasets = action.payload.datasets;
    },
    rebuildDatasets: (state) => {
      state.datasets.isLoadingProcessing = true;
    },
    loadCountryAccessRequests: (state) => {
      state.countryAccessRequests = {
        countryAccessRequestQueue: [],
        isLoading: true,
      };
    },
    loadedCountryAccessRequests: (state, action: PayloadAction<any>) => {
      state.countryAccessRequests = {
        ...state.countryAccessRequests,
        countryAccessRequestQueue: action.payload,
        isLoading: false,
      };
    },
    approveCountryAccessRequest: (
      _state,
      _action: PayloadAction<ApproveDisapprovePayloadAction>,
    ) => {},
    approveCountryAccessRequestSuccess: (
      state,
      action: PayloadAction<ApproveDisapprovePayloadAction>,
    ) => {
      const request = state.countryAccessRequests?.countryAccessRequestQueue.find(
        (r) => r.id === action.payload.id,
      );
      if (request) {
        request.state = action.payload.approve ? 'approved' : 'disapproved';
      }
    },
  },
});

export const {
  loadApprovalQueue,
  loadedApprovalQueue,
  approveUser,
  approveUserSuccess,
  addCountry,
  editCountry,
  toggleAddCountryDialog,
  toggleAddModelDataToCountryDialog,
  loadProcessingDatasets,
  loadedDatasets,
  rebuildDatasets,
  loadCountryAccessRequests,
  loadedCountryAccessRequests,
  approveCountryAccessRequest,
  approveCountryAccessRequestSuccess,
} = adminSlice.actions;

export default adminSlice.reducer;

import { createLogger } from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import persistState from 'redux-localstorage';
import { rootReducer } from './root';
import rootEpic from './rootEpic';

const epicMiddleware = createEpicMiddleware();
const loggerMiddleware = createLogger();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false })
    .concat(epicMiddleware)
    .concat(loggerMiddleware),
  // @ts-ignore TS can't recognise this for some reason
  enhancers: [persistState(['user', 'admin', 'home', 'datasetIndexes', 'datasetUploads'])],
});

epicMiddleware.run(rootEpic);

export default store;

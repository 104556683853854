/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AppBarTitleActionPayload = { title: string };

export type LoadPagePayloadAction = PayloadAction<{
  type?: string,
  uid: string,
}>;

type ContentState = {
  pages: { [uid: string]: any; },
};

const contentSlice = createSlice({
  name: 'content',
  initialState: {
    pages: {},
  } as ContentState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadPage: (_, __: LoadPagePayloadAction) => {},
    loadedPage: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.pages[action.payload.uid] = action.payload;
      }
    },
  },
});

export const { loadPage, loadedPage } = contentSlice.actions;

export default contentSlice.reducer;

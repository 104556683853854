import React from 'react';
import { Typography, Button } from '@material-ui/core';

type Props = {
  email: string,
  onSignOut: () => void
};

const WelcomeAwaitingApproval: React.FC<Props> = ({ email, onSignOut }) => (
  <>
    <Typography variant="h5" gutterBottom>Awaiting approval</Typography>
    <Typography variant="subtitle2" component="h6" gutterBottom>
      {email}
      , thank you for your interest in using MapLaria.
      You will receive an email when your application has been approved.
    </Typography>
    <Button
      variant="outlined"
      onClick={onSignOut}
    >
      Sign out
    </Button>
  </>
);

export default WelcomeAwaitingApproval;

import React from 'react';
import { Typography, Button, makeStyles } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  link: {
    display: 'block',
  },
  linkDemo: {
    display: 'block',
    width: '100%',
    marginTop: '8px',
    marginBottom: '32px',
  },
});

type Props = {
  startDemo: () => void
};

const WelcomeUnauthenticated: React.FC<Props> = ({ startDemo }) => {
  const location = useLocation();
  const classes = useStyles();
  const onClickDemo = () => startDemo();
  return (
    <>
      <Typography className="subtitle-welcome" variant="subtitle1">
        MapLaria is a web-based application for mapping the prevelance of Plasmodium falciparum
        in Sub-Saharan African countries using geostatistical methods.
      </Typography>
      <Typography
        className={classes.link}
        title="Apply"
        variant="subtitle2"
        component={Link}
        to="/about"
      >
        Learn more about the project
      </Typography>
      <Button
        className={classes.linkDemo}
        onClick={onClickDemo}
      >
        Demo
      </Button>
      <Button
        component={Link}
        to={`/sign-in${location.search}`}
        title="Sign In"
        className={classes.link}
      >
        Sign In
      </Button>
      <Button
        component={Link}
        to="/apply"
        title="Apply"
        className={classes.link}
      >
        Apply for an account
      </Button>
    </>
  );
};

export default WelcomeUnauthenticated;

import React from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {
  IconButton, makeStyles,
} from '@material-ui/core';
import history from '../../../utils/history';

const useStyles = makeStyles({
  backButton: {
    position: 'absolute',
    top: '38px',
    left: '32px',
  },
});

const BackButton: React.FC = () => {
  const classes = useStyles();
  const back = () => history.goBack();
  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="start"
      className={classes.backButton}
      onClick={back}
    >
      <ArrowBack />
    </IconButton>
  );
};

export default BackButton;

import {
  Observable, of, empty, throwError,
} from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType, combineEpics, StateObservable } from 'redux-observable';
import {
  mergeMap, map, catchError, flatMap, withLatestFrom,
} from 'rxjs/operators';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  submitArguments,
  submitArgumentsSuccessful,
  submitDrawnBoundary,
  submitDrawnBoundarySuccessful,
  submitDistrictColumnNameSuccessful,
  submitUploadedContinuousBoundary,
  SubmitDatasetArgumentsPayloadAction,
  SubmitDatasetBoundaryPayloadAction,
  uploadError,
  submitForProcessing,
  SubmitDatasetBoundarySuccessfulPayloadAction,
  createDataset,
  createDatasetSuccessfull,
  submitDistrictColumnName,
  SubmitBoundaryArgumentsPayloadAction,
  CreateDatasetPayloadAction,
} from './datasetUploadsSlice';
import { headers, URL_DATASET } from '../../../utils/ajax';
import { error, ErrorPayloadAction } from '../../error/errorSlice';
import { ExtendedDataset } from '../dataset';
import history from '../../../utils/history';
import { loadDatasetSuccessful } from '../datasetSlice';
import { RootState } from '../../root';
import { makeGetDataset } from '../datasetSelectors';
import { selectArgumentsForm } from './datasetUploadsSelectors';
import { parseFromArgumentsForm } from './utils';

const createDatasetEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType<CreateDatasetPayloadAction>(createDataset.type),
  mergeMap(
    ({ payload: { countryId } }) => ajax.post(
      `${URL_DATASET}`, { countryId }, headers,
    ).pipe(
      flatMap(({ response }) => {
        history.push(`/upload/${response.id}/point-data`);
        return of(
          createDatasetSuccessfull(),
          loadDatasetSuccessful(response),
        );
      }),
      catchError((e) => of(uploadError({ message: e.response ? e.response.message : e.message }))),
    ),
  ),
);

const submitDrawnBoundaryEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType<SubmitDatasetBoundaryPayloadAction>(submitDrawnBoundary.type),
  mergeMap(
    (action) => ajax.post(
      `${URL_DATASET}/${action.payload.id}/boundary/${action.payload.type}/geojson`, { data: action.payload.boundary }, headers,
    ).pipe(
      flatMap(({ response }) => of(
        submitDrawnBoundarySuccessful({ dataset: response, type: action.payload.type }),
        loadDatasetSuccessful(response),
      )),
      catchError((e) => of(uploadError({ message: e.response ? e.response.message : e.message }))),
    ),
  ),
);

const submitDrawnBoundarySuccessfulEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType<SubmitDatasetBoundarySuccessfulPayloadAction>(submitDrawnBoundarySuccessful.type),
  flatMap((action) => {
    if (action.payload.type === 'continuous' && action.payload.dataset.input.arguments?.averageBySpatialUnit) {
      history.push(`/upload/${action.payload.dataset.id}/boundary/aggregated/upload`);
    } else {
      history.push(`/upload/${action.payload.dataset.id}/confirm`);
    }

    return empty();
  }),
);

const submitArgumentsEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType<SubmitDatasetArgumentsPayloadAction>(submitArguments.type),
  withLatestFrom(state$),
  mergeMap(
    ([action, state]) => {
      const args = selectArgumentsForm(state, action.payload.id);
      if (!args) {
        return throwError('No args found');
      }
      return ajax.put(
        `${URL_DATASET}/${action.payload.id}`, { data: { args: parseFromArgumentsForm(args) } }, headers,
      ).pipe(
        flatMap(({ response }) => of(
          submitArgumentsSuccessful(response),
          loadDatasetSuccessful(response),
        )),
        catchError((e) => of(uploadError({ message: e.response ? e.response.message : e.message }))),
      );
    },
  ),
);

const submitDistrictColumnNameEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType<SubmitBoundaryArgumentsPayloadAction>(submitDistrictColumnName.type),
  mergeMap(
    ({ payload }) => ajax.put(
      `${URL_DATASET}/${payload.id}`, { data: { args: payload.arguments } }, headers,
    ).pipe(
      flatMap(({ response }) => of(
        submitDistrictColumnNameSuccessful(response),
        loadDatasetSuccessful(response),
      )),
      catchError((e) => of(uploadError({ message: e.response ? e.response.message : e.message }))),
    ),
  ),
);

const submitArgumentsSuccessfulEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType(submitArgumentsSuccessful.type),
  flatMap((action: PayloadAction<ExtendedDataset>) => {
    if (action.payload.cloneOf) {
      // This is a clone, can only edit arguments
      history.push(`/upload/${action.payload.id}/confirm`);
    } else if (action.payload.input.arguments?.spatiallyContinuous) {
      history.push(`/upload/${action.payload.id}/boundary/continuous`);
    } else if (action.payload.input.arguments?.averageBySpatialUnit) {
      history.push(`/upload/${action.payload.id}/boundary/aggregated/upload`);
    }
    return empty();
  }),
);

const submitUploadedContinuousBoundaryEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType<PayloadAction<string>>(submitUploadedContinuousBoundary.type),
  withLatestFrom(state$),
  flatMap(([action, state]) => {
    const dataset = makeGetDataset()(state, action.payload);
    if (dataset) {
      if (dataset.input.arguments?.averageBySpatialUnit) {
        history.push(`/upload/${dataset.id}/boundary/aggregated/upload`);
      } else {
        history.push(`/upload/${dataset.id}/confirm`);
      }
    }
    return empty();
  }),
);

const submitDistrictColumnNameSuccessfulEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType(submitDistrictColumnNameSuccessful.type),
  flatMap((action: PayloadAction<ExtendedDataset>) => {
    history.push(`/upload/${action.payload.id}/confirm`);
    return empty();
  }),
);

const submitForProcessingEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType(submitForProcessing.type),
  mergeMap(
    (action: PayloadAction<string>) => ajax.post(
      `${URL_DATASET}/${action.payload}/submit`, headers,
    ).pipe(
      flatMap(({ response }) => of(
        loadDatasetSuccessful(response),
      )),
      catchError((e) => of(uploadError({ message: e.response ? e.response.message : e.message }))),
    ),
  ),
);

// Forward on any local errors to the global error handler
const errorUploadEpic = (action$: Observable<any>) => action$.pipe(
  ofType(uploadError.type),
  map((action: PayloadAction<ErrorPayloadAction>) => error({ message: action.payload.message })),
);

export default combineEpics(
  createDatasetEpic,
  errorUploadEpic,
  submitArgumentsEpic,
  submitArgumentsSuccessfulEpic,
  submitDistrictColumnNameEpic,
  submitDrawnBoundaryEpic,
  submitDrawnBoundarySuccessfulEpic,
  submitUploadedContinuousBoundaryEpic,
  submitForProcessingEpic,
  submitDistrictColumnNameSuccessfulEpic,
);

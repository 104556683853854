import React from 'react';
import { createMuiTheme, MuiThemeProvider, makeStyles } from '@material-ui/core';

const background = require('../../img/background-2.jpg');

const theme = createMuiTheme({
  overrides: {
    MuiContainer: {
      root: {
        '&.page': {
          display: 'flex !important',
          textAlign: 'center',
          alignItems: ' center',
          justifyContent: 'center',
          height: '100vh',
          flexDirection: 'column',
        },
      },
    },
    MuiTextField: {
      root: {
        '&.field': {
          display: 'block',
          marginBottom: '16px',
          width: '100%',
        },
      },
    },
    MuiInputBase: {
      root: {
        width: '100%',
      },
    },
    MuiTypography: {
      root: {
        '&.subtitle': {
          marginBottom: '32px',
        },
        '&.subtitle-welcome': {
          marginBottom: '8px',
        },
      },
    },
    MuiFormControl: {
      root: {
        '&.field-select': {
          marginBottom: '28px',
          textAlign: 'left',
          width: '100%',
        },
      },
    },
    MuiPaper: {
      root: {
        '&.form-container': {
          position: 'relative',
          padding: '32px',
          overflow: 'scroll',
        },
        form: {
          backgroundColor: 'pink',
        },
      },
    },
  },
  typography: {
    subtitle1: {
      maxWidth: '400px',
      marginBottom: '32px',
    },
  },
});

const useStyles = makeStyles({
  background: {
    background: `url(${background})`,
    backgroundSize: 'cover',
  },
});

const OnboardingTheme: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.background}>
      <MuiThemeProvider theme={theme}>
        {children}
      </MuiThemeProvider>
    </div>
  );
};

export default OnboardingTheme;

import React, { lazy, Suspense, useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { connect, ConnectedProps } from 'react-redux';
import {
  Router,
  Switch,
} from 'react-router-dom';
import OnboardingRoute from './hoc/OnboardingRoute';
import PrivateRoute from './hoc/PrivateRoute';
import { RootState } from './store/root';
import { loadUser } from './store/user/userSlice';
import Loading from './ui/components/loading/Loading';
import RegisterPage from './user/containers/RegisterPage';
import SignInPage from './user/containers/SignInPage';
import WelcomePage from './user/containers/WelcomePage';
import history from './utils/history';
import ResetPasswordPage from './user/containers/ResetPasswordPage';
import ForgotPasswordPage from './user/containers/ForgotPasswordPage';

const Home = lazy(() => import('./home/components/Home'));
const AboutPage = lazy(() => import('./about/containers/AboutPage'));

const mapStateToProps = (state: RootState) => ({
  title: state.home.appBarTitle,
});
const mapDispatch = {
  startLoadUser: loadUser,
};
const connector = connect(
  mapStateToProps,
  mapDispatch,
);
type PropsFromRedux = ConnectedProps<typeof connector>;

const Routes: React.FC<PropsFromRedux> = ({ startLoadUser, title }) => {
  useEffect(() => {
    startLoadUser();
  }, [startLoadUser]);

  return (
    <DocumentTitle title={`MapLaria | ${title}`}>
      <Router history={history}>
        <Suspense fallback={<Loading show />}>
          <Switch>
            <OnboardingRoute path="/sign-in" component={SignInPage} />
            <OnboardingRoute path="/forgot-password" component={ForgotPasswordPage} />
            <OnboardingRoute path="/reset-password" component={ResetPasswordPage} />
            <OnboardingRoute path="/apply" component={RegisterPage} />
            <OnboardingRoute path="/about" component={AboutPage} />
            <OnboardingRoute path="/landing" component={WelcomePage} />
            <PrivateRoute path="/" component={Home} />
          </Switch>
        </Suspense>
      </Router>
    </DocumentTitle>
  );
};

export default connector(Routes);

import { useState, ChangeEvent } from 'react';

export default (initialValue: string) => {
  const [value, setValue] = useState(initialValue);
  const [dirty, setDirty] = useState(false);

  return {
    value,
    setValue,
    reset: () => setValue(''),
    dirty,
    bind: {
      value,
      onChange: (
        event: ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>,
      ) => {
        setValue(event.target.value as string);
        setDirty(true);
      },
    },
  };
};

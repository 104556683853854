import { Observable } from 'rxjs';
import { ofType, combineEpics } from 'redux-observable';
import { mapTo } from 'rxjs/operators';
import { startLoading, stopLoading } from './loadingSlice';
import {
  signIn, register, signInSuccessful, registrationSuccessful, signOutSuccessful,
} from '../../../store/user/userSlice';
import { loadApprovalQueue } from '../../../admin/state/adminSlice';
import { setName, editSaved } from '../../../store/dataset/datasetSlice';

const startLoadingEpic = (action$: Observable<any>) => action$.pipe(
  ofType([
    signIn.type,
    register.type,
    loadApprovalQueue.type,
    setName.type,
  ]),
  mapTo(startLoading()),
);

const stopLoadingEpic = (action$: Observable<any>) => action$.pipe(
  ofType([
    signInSuccessful.type,
    signOutSuccessful.type,
    registrationSuccessful.type,
    editSaved.type,
  ]),
  mapTo(stopLoading()),
);

export default combineEpics(startLoadingEpic, stopLoadingEpic);

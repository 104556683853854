import { DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
// @ts-ignore
import { RichText } from 'prismic-reactjs';
import { RootState } from '../../store/root';
import { loadPage } from '../../store/content/contentSlice';
import { linkResolver } from '../../store/prismic';

type Props = {
  state: 'terms' | 'privacy' | undefined
  onClose: () => void;
};

const mapStateToProps = (state: RootState, props: Props) => ({
  page: props.state ? state.content.pages[props.state] : undefined,
});
const mapDispatchToProps = {
  startLoadPage: loadPage,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsWithRedux = Props & ConnectedProps<typeof connector>;

const TermsDialog: React.FC<PropsWithRedux> = ({
  onClose, state, page, startLoadPage,
}) => {
  useEffect(() => {
    if (state) {
      startLoadPage({ type: 'page', uid: state });
    }
  });
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog open={state !== undefined} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{page?.data.title}</DialogTitle>
      <DialogContent>
        {page
          && <RichText render={page.data.body} linkResolver={linkResolver} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connector(TermsDialog);

import { Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType, combineEpics } from 'redux-observable';
import {
  mergeMap, map, catchError, flatMap,
} from 'rxjs/operators';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  LoadDatasetPayloadAction,
  loadDatasetSuccessful,
  loadDataset,
  cancelProcessingSuccessful,
  cancelProcessing,
  setPublic,
  SetPublicPayloadAction,
  SetNamePayloadAction,
  editSaved,
  setName,
  cloneDataset,
  deleteDataset,
  deletedDataset,
} from './datasetSlice';
import { error } from '../error/errorSlice';
import { URL_DATASET, headers } from '../../utils/ajax';
import history from '../../utils/history';

const loadDatasetEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType<LoadDatasetPayloadAction>(loadDataset.type),
  mergeMap(
    (action) => ajax.get(
      `${URL_DATASET}/${action.payload.id}`,
    )
      .pipe(
        map(({ response }) => loadDatasetSuccessful(response)),
        catchError((e) => of(error({ message: e.message }))),
      ),
  ),
);

const cancelProcessingEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType<PayloadAction<string>>(cancelProcessing.type),
  mergeMap(
    (action) => ajax.post(
      `${URL_DATASET}/${action.payload}/cancel`, headers,
    ).pipe(
      flatMap(({ response }) => of(
        cancelProcessingSuccessful(response),
        loadDatasetSuccessful(response),
      )),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

const setPublicEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType<SetPublicPayloadAction>(setPublic.type),
  mergeMap(
    (action) => ajax.put(
      `${URL_DATASET}/${action.payload.id}/meta`, { isPublic: action.payload.isPublic }, headers,
    ).pipe(
      flatMap(({ response }) => of(
        editSaved(action.payload.id),
        loadDatasetSuccessful(response),
      )),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

const cloneEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType<PayloadAction<string>>(cloneDataset.type),
  mergeMap(
    (action) => ajax.post(
      `${URL_DATASET}/${action.payload}/clone`, headers,
    ).pipe(
      flatMap(({ response }) => {
        history.push(`/upload/${response.id}/arguments`);
        return of(
          editSaved(action.payload),
          loadDatasetSuccessful(response),
        );
      }),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

const setNameEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType<SetNamePayloadAction>(setName.type),
  mergeMap(
    (action) => ajax.put(
      `${URL_DATASET}/${action.payload.id}/meta`, { name: action.payload.name }, headers,
    ).pipe(
      flatMap(({ response }) => of(
        editSaved(action.payload.id),
        loadDatasetSuccessful(response),
      )),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

const deleteEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType<PayloadAction<string>>(deleteDataset.type),
  mergeMap(
    (action) => ajax.delete(
      `${URL_DATASET}/${action.payload}`, headers,
    ).pipe(
      map(() => deletedDataset(action.payload)),
      catchError((e) => of(error({ message: e.message }))),
    ),
  ),
);

export default combineEpics(
  loadDatasetEpic,
  cancelProcessingEpic,
  setPublicEpic,
  setNameEpic,
  cloneEpic,
  deleteEpic,
);

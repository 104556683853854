/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  Container,
  FormControl,
  FormHelperText, Input, InputLabel, ListSubheader, MenuItem, Paper, Select, Typography,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setAppBarTitle } from '../../home/state/homeSlice';
import useInput from '../../hooks/useInput';
import { loadCountries } from '../../store/model/modelSlice';
import { RootState } from '../../store/root';
import BackButton from '../../ui/components/buttons/BackButton';
import Loading from '../../ui/components/loading/Loading';
import { register } from '../../store/user/userSlice';
import './SignIn.scss';
import TermsLinks from './TermsLinks';
import PasswordInput from '../components/PasswordInput';

const mapStateToProps = (state: RootState) => ({
  isRegistering: state.user.isRegistering,
  user: state.user.user,
  countries: state.model.countries.slice().sort((a, b) => a.name.localeCompare(b.name)),
});
const mapDispatch = {
  onRegisterSubmit: register,
  startLoadCountries: loadCountries,
  setTitle: setAppBarTitle,
};
const connector = connect(
  mapStateToProps,
  mapDispatch,
);
type PropsFromRedux = ConnectedProps<typeof connector>;

const RegisterPage: React.FC<PropsFromRedux> = ({
  isRegistering, onRegisterSubmit, user, startLoadCountries, countries,
  setTitle,
}) => {
  const { value: name, bind: bindName, dirty: nameDirty } = useInput('');
  const { value: organisation, bind: bindOrganisation, dirty: organisationDirty } = useInput('');
  const { value: email, bind: bindEmail, dirty: emailDirty } = useInput('');
  const { value: password, bind: bindPassword, dirty: passwordDirty } = useInput('');
  const { value: passwordRepeat, bind: bindPasswordRepeat, dirty: passwordRepeatDirty } = useInput('');
  const { value: country, bind: bindCountry } = useInput('');
  const { value: reason, bind: bindReason, dirty: reasonDirty } = useInput('');

  const validateEmail = (str: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(str).toLowerCase());
  };

  useEffect(() => {
    setTitle({ title: 'Apply' });
  });

  useEffect(() => {
    startLoadCountries();
  }, [startLoadCountries]);

  const nameValid = name.length > 1;
  const organisationValid = organisation.length > 1;
  const emailValid = validateEmail(email);
  const passwordValid = password.length > 5;
  const passwordRepeatValid = password === passwordRepeat;

  const countryValid = country !== undefined && country.length > 0;
  const reasonValid = reason.length > 3;

  const isValid = nameValid && organisationValid && emailValid
    && passwordValid && passwordRepeatValid && countryValid && reasonValid;

  if (user) {
    return <Redirect to="/" />;
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onRegisterSubmit({
      name, organisation, email, password, countryId: country, reasonForApplying: reason,
    });
    return false;
  };

  return (
    <Container className="page-register center-content page">
      <Loading show={isRegistering} />
      <Paper className="form-container">
        <BackButton />
        <header>
          <Typography variant="h3" component="h1" gutterBottom>
            Apply
          </Typography>
          <Typography className="subtitle" variant="subtitle1" gutterBottom>
            {/* eslint-disable-next-line max-len */}
            Fill in the form below to register for access to MapLaria, you will receive an email when access has been granted.
          </Typography>
        </header>
        <form id="sign-in" onSubmit={onSubmit} autoComplete="on">
          <FormControl className="field-select" required>
            <InputLabel htmlFor="grouped-select">Country</InputLabel>
            {countries && (
              <Select variant="outlined" defaultValue="" {...bindCountry} input={<Input id="grouped-select" />}>
                <ListSubheader>Models available</ListSubheader>
                {countries.filter((c) => c.available).map((c) => (
                  <MenuItem value={c.id}>{c.name}</MenuItem>
                ))}
                <ListSubheader>Models unavailable</ListSubheader>
                {countries.filter((c) => !c.available).map((c) => (
                  <MenuItem value={c.id}>{c.name}</MenuItem>
                ))}
              </Select>
            )}
            <FormHelperText>
              You can still apply if we do not yet have a model for your country,
              we will try add it where possible.
            </FormHelperText>
          </FormControl>
          <TextField required label="Full name" {...bindName} className="field" variant="outlined" error={!nameValid && nameDirty} type="text" autoComplete="name" />
          <TextField required label="Organisation" {...bindOrganisation} className="field" variant="outlined" error={!organisationValid && organisationDirty} type="text" autoComplete="organization" />
          <TextField required label="Email" {...bindEmail} className="field" variant="outlined" error={!emailValid && emailDirty} type="email" autoComplete="email-address" />
          <PasswordInput
            id="password"
            label="Password"
            value={bindPassword.value}
            onChange={bindPassword.onChange}
            helperText="Must be > 6 characters long"
            error={!passwordValid && passwordDirty}
          />
          <PasswordInput
            id="password"
            label="Repeat password"
            value={bindPasswordRepeat.value}
            onChange={bindPasswordRepeat.onChange}
            error={!passwordRepeatValid && passwordRepeatDirty}
            helperText={!passwordRepeatValid && passwordRepeatDirty ? 'Passwords do not match' : undefined}
          />
          <TextField multiline required label="Reason for using MapLaria" {...bindReason} className="field" variant="outlined" type="text" error={!reasonValid && reasonDirty} />
          <FormHelperText style={{ textAlign: 'center' }}>
            <TermsLinks />
          </FormHelperText>
          <Button style={{ marginTop: '16px' }} type="submit" disabled={!isValid}>Apply</Button>
        </form>
      </Paper>
    </Container>
  );
};

export default connector(RegisterPage);

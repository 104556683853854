// We must not persist actual datasets to local storage, they are too large
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Boundary } from '../../../../../types/BaselinePointData';
import {
  BoundaryArguments, ContinuousOrAggregatedDisplay, ExtendedDatasetDTO,
} from '../../../../../types/Dataset';
import { ErrorPayloadAction } from '../../error/errorSlice';
import { loadDatasetSuccessful } from '../datasetSlice';
import { signOutSuccessful } from '../../user/userSlice';
import { parseToArgumentsForm } from './utils';
import { ArgumentsForm } from './types';

export type CreateDatasetPayloadAction = PayloadAction<{
  countryId: string;
}>;

export type SubmitDatasetArgumentsPayloadAction = PayloadAction<{
  id: string;
}>;

export type SubmitBoundaryArgumentsPayloadAction = PayloadAction<{
  id: string;
  arguments: BoundaryArguments;
}>;

export type SubmitDatasetBoundaryPayloadAction = PayloadAction<{
  id: string;
  boundary: Boundary;
  type: ContinuousOrAggregatedDisplay;
}>;

export type StepName = 'select-country' | 'point-data' | 'arguments' | 'boundary-data' | 'confirm';

export type SetActiveStepPayloadAction = PayloadAction<{
  id?: string;
  stepName: StepName;
}>;

export type SetArgumentsFormStatePayloadAction = PayloadAction<{
  id: string;
  form: ArgumentsForm;
}>;

export type SubmitDatasetBoundarySuccessfulPayloadAction = PayloadAction<{
  dataset: ExtendedDatasetDTO,
  type: ContinuousOrAggregatedDisplay
}>;

export type UploadState = {
  activeStep: StepName
  argumentsForm?: ArgumentsForm
};

type DatasetUploadState = {
  selectedCountry: string | undefined
  creatingDataset: boolean
  submittingArguments: { [key: string]: boolean | undefined }
  submittingBoundaryData: { [key: string]: Boundary | undefined }
  submittingForProcessing: { [key: string]: boolean | undefined }
  uploadState: { [key: string]: UploadState | undefined }
};

// todo there's lots of unused boilerplate here, remove
// THIS IS PERSISTED
const datasetUploadSlice = createSlice({
  name: 'datasetUploads',
  initialState: {
    selectedCountry: '',
    creatingDataset: false,
    submittingArguments: {},
    submittingBoundaryData: {},
    submittingForProcessing: {},
    uploadState: {},
  } as DatasetUploadState,
  reducers: {
    createDataset: (state, action: CreateDatasetPayloadAction) => {
      if (!state.uploadState) {
        state.uploadState = {};
      }
      state.creatingDataset = true;
      state.selectedCountry = action.payload.countryId;
    },
    createDatasetSuccessfull: (state) => {
      state.creatingDataset = false;
    },
    setArgumentsFormState: (state, action: SetArgumentsFormStatePayloadAction) => {
      const upload = state.uploadState[action.payload.id];
      if (upload) {
        upload.argumentsForm = {
          ...action.payload.form,
        };
      }
    },
    submitArguments: (state, action: SubmitDatasetArgumentsPayloadAction) => {
      state.submittingArguments[action.payload.id] = true;
    },
    submitArgumentsSuccessful: (
      state, action: PayloadAction<ExtendedDatasetDTO>,
    ) => {
      state.submittingArguments[action.payload.id] = undefined;
    },
    submitDistrictColumnName: (state, action: SubmitBoundaryArgumentsPayloadAction) => {
      state.submittingArguments[action.payload.id] = true;
    },
    submitDistrictColumnNameSuccessful: (
      state, action: PayloadAction<ExtendedDatasetDTO>,
    ) => {
      state.submittingArguments[action.payload.id] = undefined;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submitUploadedContinuousBoundary(_, __: PayloadAction<string>) { },
    submitDrawnBoundary: (
      state, action: SubmitDatasetBoundaryPayloadAction,
    ) => {
      state.submittingBoundaryData[action.payload.id] = action.payload.boundary;
    },
    submitDrawnBoundarySuccessful: (
      state,
      action: SubmitDatasetBoundarySuccessfulPayloadAction,
    ) => {
      state.submittingBoundaryData[action.payload.dataset.id] = undefined;
    },
    submitForProcessing(state, action: PayloadAction<string>) {
      state.submittingForProcessing[action.payload] = true;
    },
    submitForProcessingSuccessful(state, action: PayloadAction<string>) {
      state.submittingForProcessing[action.payload] = undefined;
    },
    setActiveStep(state, action: SetActiveStepPayloadAction) {
      if (!state.uploadState) {
        state.uploadState = {};
      }
      if (action.payload.id) {
        state.uploadState[action.payload.id] = {
          ...state.uploadState[action.payload.id],
          activeStep: action.payload.stepName,
        };

        // Set the default arguments form state
        const dsState = state.uploadState[action.payload.id];
        if (dsState && dsState.argumentsForm === undefined) {
          dsState.argumentsForm = {
            year: '',
            averageBySpatialUnit: false,
            spatiallyContinuous: false,
            summary: {
              pointEstimates: false,
              standardErrors: false,
              quantiles: undefined,
              thresholdOptions: undefined,
            },
          };
        }
      }
    },
    setSelectedCountry(state, action: PayloadAction<string>) {
      state.selectedCountry = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uploadError(state, _action: PayloadAction<ErrorPayloadAction>) {
      state.submittingArguments = {};
      state.submittingBoundaryData = {};
      state.submittingForProcessing = {};
    },
  },
  extraReducers: {
    [signOutSuccessful.type]: (state) => {
      state.selectedCountry = undefined;
    },
    [loadDatasetSuccessful.type]: (state, action: PayloadAction<ExtendedDatasetDTO>) => {
      state.submittingArguments[action.payload.id] = undefined;
      state.submittingBoundaryData[action.payload.id] = undefined;
      state.submittingForProcessing[action.payload.id] = undefined;

      // Server uses numbers for quantiles and thresholds,
      // we must convert to strings for use in the form
      const uploadState = state.uploadState[action.payload.id];
      if (uploadState) {
        if (action.payload.input.arguments) {
          uploadState.argumentsForm = parseToArgumentsForm(action.payload.input.arguments);
        } else if (action.payload.input.cloneOfArguments) {
          uploadState.argumentsForm = parseToArgumentsForm(action.payload.input.cloneOfArguments);
        }
      }
    },
  },
});

export const {
  createDataset,
  createDatasetSuccessfull,
  submitArguments,
  submitArgumentsSuccessful,
  submitDrawnBoundary,
  submitDrawnBoundarySuccessful,
  submitForProcessing,
  submitForProcessingSuccessful,
  uploadError,
  submitDistrictColumnNameSuccessful,
  submitDistrictColumnName,
  submitUploadedContinuousBoundary,
  setActiveStep,
  setSelectedCountry,
  setArgumentsFormState,
} = datasetUploadSlice.actions;

export default datasetUploadSlice.reducer;

import Prismic from 'prismic-javascript';

const apiEndpoint = 'https://maplaria.cdn.prismic.io/api/v2';
const apiToken = 'MC5YcFhmNmhJQUFDQUFwMnF4.GQPvv70nW3Iu77-977-977-977-977-977-9dO-_ve-_vSYD77-977-977-977-977-9W--_ve-_ve-_ve-_ve-_vXM_ZA';

async function prismicApi() {
  return Prismic.getApi(apiEndpoint, { accessToken: apiToken });
}

export async function getPages(): Promise<any[]> {
  const api = await prismicApi();
  const res = await api.query(
    Prismic.Predicates.at('my.page.show_in_sidebar', 'true'),
    // '',
    { fetch: ['page.title', 'page.show_in_sidebar', 'page.page_order'] },
  );
  return res.results.sort((a, b) => a.data.page_order - b.data.page_order);
}

export async function getPage(slug: string): Promise<any> {
  const api = await prismicApi();
  const res = await api.getByUID('page', slug);
  return res;
}

export async function getHelpPage(slug: string): Promise<any> {
  const api = await prismicApi();
  const res = await api.getByUID('tutorial', slug);
  return res;
}

export function linkResolver(doc: any) {
  // Define the url depending on the document type
  if (doc.type === 'page') {
    return `/page/${doc.uid}`;
  } if (doc.type === 'help') {
    return `/help/${doc.uid}`;
  }
  return '/';
}

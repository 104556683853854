import { Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { headers, URL_MODEL } from '../../utils/ajax';
import { error } from '../error/errorSlice';
import { loadCountries, loadCountriesSuccessful } from './modelSlice';

const loadCountriesEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType(loadCountries.type),
  mergeMap(
    () => ajax.get(
      `${URL_MODEL}/country`,
      headers,
    ).pipe(
      map(({ response }) => loadCountriesSuccessful(response.data)),
      catchError(({ message }) => of(error({ message }))),
    ),
  ),
);

export default combineEpics(loadCountriesEpic);

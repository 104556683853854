/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

type LoadingState = {
  isLoading: boolean,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    isLoading: false,
  } as LoadingState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  startLoading,
  stopLoading,
} = loadingSlice.actions;

export default loadingSlice.reducer;

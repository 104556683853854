import { combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { Action } from 'redux';
import { catchError } from 'rxjs/operators';

import userEpic from './user/userEpic';
import loadingEpic from '../ui/components/loading/loadingEpic';
import adminEpic from '../admin/state/adminEpic';
import datasetEpic from './dataset/datasetEpic';
import datasetUploadsEpic from './dataset/uploads/datasetUploadsEpic';
import modelEpic from './model/modelEpic';
import errorEpic from './error/errorEpic';
import contentEpic from './content/contentEpic';
import homeEpic from '../home/state/homeEpic';
import datasetIndexEpic from './dataset/datasetIndexEpic';
import supportEpic from './support/supportEpic';

/**
 * https://redux-observable.js.org/docs/basics/SettingUpTheMiddleware.html
 * Uncaught errors can bubble up to the root epic and cause the entire stream to terminate.
 * As a consequence, epics registered in the middleware will no longer run in your application.
 * To alleviate this issue, you can add a global error handler to the root epic that
 * catches uncaught errors and resubscribes to the source stream.
 */
export default (
  action$: ActionsObservable<Action>,
  store$: StateObservable<any>, dependencies: any,
) => combineEpics(
  userEpic,
  loadingEpic,
  adminEpic,
  datasetIndexEpic,
  datasetEpic,
  datasetUploadsEpic,
  errorEpic,
  modelEpic,
  homeEpic,
  contentEpic,
  supportEpic,
)(action$, store$, dependencies).pipe(
  catchError((error, source) => {
    // eslint-disable-next-line no-console
    console.error('global catchError hit');
    console.error(error);
    return source;
  }),
);

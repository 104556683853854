import { combineReducers } from 'redux';
import adminReducer from '../admin/state/adminSlice';
import homeReducer from '../home/state/homeSlice';
import userReducer from './user/userSlice';
import datasetReducer from './dataset/datasetSlice';
import datasetIndexReducer from './dataset/datasetIndexSlice';
import datasetUploadsReducer from './dataset/uploads/datasetUploadsSlice';
import errorReducer from './error/errorSlice';
import modelReducer from './model/modelSlice';
import supportReducer from './support/supportSlice';
import contentReducer from './content/contentSlice';

export const rootReducer = combineReducers({
  user: userReducer,
  home: homeReducer,
  admin: adminReducer,
  error: errorReducer,
  // Because datasets are potentially very large we only persist the metadata
  datasetIndexes: datasetIndexReducer, // This is persisted
  datasetUploads: datasetUploadsReducer, // This is persisted
  dataset: datasetReducer, // This is NOT persisted
  model: modelReducer,
  content: contentReducer,
  support: supportReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
